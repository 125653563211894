@import './../../../app/mixins';

$color-table-1: #d8dbdf;
$color-table-2: $color-alabaster;
$color-table-3: #ebeef3;
$color-table-4: #e3e5e8;
$color-table-5: $color-grey;
$color-table-7: #555;
$color-table-8: #ececec;
$color-background-picked: $color-light-blue;

.sticky {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-height: 100%;

  thead {
    flex-shrink: 0;
  }

  tbody {
    flex: 1;
    max-width: 100%;
  }
}

.flexed {
  tr {
    display: flex;
    flex-wrap: nowrap;
    border-width: 0;

    th {
      border-bottom: none;
    }

    th:first-child,
    td:first-child {
      min-width: 60px !important;
    }

    th + th,
    td + td {
      border-left-width: 0;
      flex: 1;
    }

    th:last-child,
    td:last-child {
      flex-grow: 0.9;
      flex-shrink: 1;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: $color-white;
  color: $color-table-5;
  font-size: 11px;
  line-height: 13px;
  vertical-align: middle;
}

.headTr {
  border: 1px solid $color-table-1;
  background-color: $color-table-3;
}

.th {
  padding: 11px 6px;
  border: 1px solid $color-table-1;
  border-bottom: none;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
}

.tr {
  &.assigned {
    background-color: #fffab9;
  }
}

.tr {
  &:last-child td {
    border-bottom: 1px solid $color-table-1;
  }
}

.tr:nth-of-type(2n) {
  background-color: $color-table-2;

  &.assigned {
    background-color: #fffab9;
  }
}

.td {
  padding: 11px 6px;
  border: 1px solid $color-table-1;
  border-bottom: 0;
}

.type_2 {
  font-size: 14px;
  line-height: 17px;

  .th {
    border-color: $color-table-3;
    border-bottom-color: $color-table-1;
  }

  .td {
    padding: 15px 6px;
  }
}

.type_3 {
  .td {
    padding: 13px 6px;
  }

  .headTr {
    border-color: transparent;
    background-color: $color-white;
  }

  .th {
    position: relative;
    padding: 4px 6px;
    border: none;

    &:before {
      content: '';
      position: absolute;
      top: 40%;
      bottom: 0;
      left: 0;
      width: 1px;
      background: $color-table-1;
    }

    &:first-child:before {
      display: none;
    }
  }

  .tr {
    &:nth-of-type(2n) {
      background-color: $color-white;
    }

    &:nth-of-type(2n + 1) {
      background-color: $color-table-2;
    }
  }
}

.type_4 {
  .headTr {
    border: none;
    border-bottom: 1px solid $color-table-1;
    background-color: $color-white;
  }

  .th {
    position: relative;
    padding: 5px 6px;
    border: none;
  }

  .td {
    padding: 10px 6px;
  }

  .tr {
    &:nth-of-type(2n) {
      background-color: $color-white;
    }

    &:nth-of-type(2n + 1) {
      background-color: $color-table-2;
    }
  }
}

.type_5 {
  .headTr {
    padding: 6px 14px;

    &:nth-of-type(2) {
      padding: 4px 6px;
      background-color: $color-table-4;
    }
  }

  .th {
    border: none;
    position: relative;
  }

  .headTr:first-child > .th {
    padding-right: 15px;
  }

  .caretIcon {
    vertical-align: middle;
    position: absolute;
    right: 6px;
    top: calc(50% - 7px);
  }

  .caretIcon > svg {
    width: 9px;
    height: 13px;
  }

  .th:last-child {
    .caretIcon {
      right: 26px;
    }
  }
}

.type_6 {
  .headTr {
    background-color: #f0f0f0;
    font-weight: 500;
    border: 1px solid #e1e1e1;
  }

  .tr,
  .tr:nth-of-type(2n) {
    background-color: $color-white;
    border: none;
  }

  .td,
  .th {
    border: none;
  }

  .th {
    padding: 7px 6px;
    font-weight: 500;
  }

  .td {
    padding: 10px 6px;
    font-size: 12px;
    line-height: 14px;
    border-top: 1px solid transparent;

    &:first-child {
      padding-right: 0;
      text-align: center;
      border-left: 1px solid transparent;
    }

    &:nth-of-type(2) {
      $width: 130px;

      font-weight: bold;
      min-width: $width;
      max-width: $width;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }
  }
}

.type_7 {
  color: $color-table-7;

  .headTr {
    font-weight: 500;
    background: none;
    border: none;
  }

  .tr {
    background-color: $color-white;
    border: none;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    color: #555;
  }

  .td,
  .th {
    border: none;
  }

  .th {
    padding: 7px 6px;
    font-weight: bold;
    font-size: 12px;
  }

  .td {
    padding: 6px;
    font-size: 13px;
    line-height: 14px;

    &:first-child {
      border-left: 1px solid transparent;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }
  }
}

.type_8 {
  color: $color-table-7;

  .headTr {
    font-weight: 500;
    background-color: $color-table-2;
    border: none;
    border-top: 1px solid $color-table-8;
  }

  .tr {
    border: none;
    border-top: 1px solid $color-table-8;
    color: #555;

    &:nth-of-type(2n + 2) {
      background-color: $color-table-2;
    }
  }

  .td,
  .th {
    border: none;
  }

  .th {
    padding: 7px 6px;
    font-weight: bold;
    font-size: 12px;
  }

  .td {
    padding: 6px;
    font-size: 13px;
    line-height: 14px;

    &:first-child {
      border-left: 1px solid transparent;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }
  }
}

.type_9 {
  .headTr {
    border: none;
    border-bottom: 1px solid $color-table-1;
    background-color: $color-white;
  }

  .th {
    position: relative;
    padding: 5px 6px;
    border: none;
  }

  .td {
    padding: 4px 6px;
    border-top: 1px solid $color-table-1;

    &:first-child {
      border-left: 1px solid $color-table-1;
      border-right: none;
    }

    &:last-child {
      border-right: 1px solid $color-table-1;
      border-left: none;
    }
  }

  .tr {
    &:nth-of-type(2n) {
      background-color: $color-white;
    }

    &:nth-of-type(2n + 1) {
      background-color: $color-table-2;
    }
  }
}

.type_10 {
  .headTr {
    border: 1px solid $color-table-1;
    background-color: #f0f0f0;
  }

  .th {
    font-weight: 600;
    font-size: 10px;
  }

  .td {
    font-size: 11px;
  }

  .th,
  .td {
    padding: 12px 6px;
  }

  th:first-child label,
  td:first-child label {
    justify-content: center;
  }

  .tr {
    &:nth-of-type(2n) {
      background-color: $color-white;
    }

    &:nth-of-type(2n + 1) {
      background-color: $color-table-2;
    }
  }
}

.type_11 {
  max-width: 100%;
  width: 100%;

  .th {
    cursor: default;
    position: relative;
    padding-right: 10px;

    .headerContent {
      & > div {
        display: inline-block;
      }
    }

    & > div {
      display: inline-block;
    }

    .caretIcon {
      vertical-align: middle;
      position: absolute;
      right: 6px;
    }

    .caretIcon > svg {
      width: 9px;
      height: 13px;
    }
  }
}

.type_12 {
  .headTr {
    border: none;
    border-bottom: 1px solid $color-table-1;
    background-color: $color-table-3;
  }

  .th {
    position: relative;
    padding: 13px 6px 0;

    &:not(:last-child) {
      border-right: none;
    }

    &:not(:first-child) {
      border-left: none;
    }
  }

  .td {
    padding: 4px 6px;
    border-top: 1px solid $color-table-1;
  }

  .tr {
    &:nth-of-type(2n) {
      background-color: $color-white;
    }

    &:nth-of-type(2n + 1) {
      background-color: $color-table-2;
    }
  }
}

.hidden {
  visibility: hidden;
}

// for picked tr
.picked,
.isHoverable .tr:hover,
.isHoverable .tr.active {
  background-color: $color-background-picked !important;

  .td {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: $color-background-picked !important;

    @include primary-border-top-color();
    @include primary-border-bottom-color();
  }

  .td:first-child {
    border-left: 1px solid transparent;

    @include primary-border-left-color();
  }

  .td:last-child {
    border-right: 1px solid transparent;

    @include primary-border-right-color();
  }
}

// for showing hidden elements on hover
.picked,
.tr:hover,
.tr.active {
  .hidden {
    visibility: visible;
  }
}

.expander {
  border: 0;
  outline: 0;
  outline-offset: 0;
  background-color: $color-background-picked !important;

  &:hover .td {
    border-bottom: 1px solid transparent !important;
  }

  .td {
    border-color: transparent;
    background-color: $color-background-picked !important;
    border-top: 1px solid transparent;

    @include primary-border-top-color();
  }

  .td:first-child {
    border-left: 1px solid transparent;

    @include primary-border-left-color();
  }

  .td:last-child {
    border-right: 1px solid transparent;

    @include primary-border-right-color();
  }
}

.expandedRow {
  background-color: $color-background-picked !important;
  border: 1px solid transparent;
  border-top: 0;

  @include primary-border-color();

  .td {
    border-bottom: 1px solid transparent;

    @include primary-border-bottom-color();
  }
}

.th.sortableTh {
  cursor: pointer;
}

.noDataCell {
  padding: 5px;
  vertical-align: center;
  text-align: center;
}
